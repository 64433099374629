<template>
    <b-container fluid class="p-0 m-0">
        <b-navbar toggleable="lg" type="light" class="menu p-0 pt-1 pb-1 d-none d-lg-block" v-if="menu">

            <b-navbar-toggle target="nav-collapse" class="mb-3"></b-navbar-toggle>

                <b-navbar-nav align="center"  class="m-auto">
                    <div v-for="(item, index) in menu" :key="'item'+index">
                        <b-nav-item
                                :to="(item.params)?{name: item.url, params: JSON.parse(item.params)}:{name: item.url}"
                                class="menu_item mb-1 mb-lg-0"
                                v-if="!item.menus">
                            {{item.title}}
                        </b-nav-item>
                        <b-nav-item-dropdown
                                ref="dropdown"
                                :text="item.title"
                                class="menu_item"
                                v-if="item.menus"
                                left>
                            <b-dropdown-item :to="(item.params)?{name: item.url, params: JSON.parse(item.params)}:{name: item.url}"
                                             class="menu_subitem"><span class="sub_checked">{{item.title}}</span></b-dropdown-item>
                            <b-dropdown-item :to="(sub.params)?{name: sub.url, params: JSON.parse(sub.params)}:{name: sub.url}"
                                             class="menu_subitem"
                                             v-for="(sub, j) in filterEnable(item.menus)"
                                             :key="'subitem'+j">{{sub.title}}</b-dropdown-item>
                        </b-nav-item-dropdown>
                    </div>
                   
                </b-navbar-nav>

                <b-navbar-nav class="m-auto d-block d-lg-none">
                        <b-nav-item
                                class="mb-1 mb-lg-0">
                            <div class="eye_button_collapse">
                                <Icon :icon="icons.eyeIcon" color="#f48611" height="12" :rotate="2" :horizontalFlip="true" :verticalFlip="true" />
                            </div>
                        </b-nav-item>
                </b-navbar-nav>
        </b-navbar>
        <div class="d-block d-lg-none menu-collapse">
            <b-dropdown size="lg" dropleft variant="link" toggle-class="text-decoration-none" no-caret no-flip>
                <template #button-content>
                    <Icon :icon="icons.menuFill" height="67" color="#666" />
                </template>

                <b-container fluid v-for="(item, index) in menu" :key="'item'+index" class="p-0">
                    <b-dropdown-item :class="(!item.menus)?'menu_collapse_item pt-1 pb-1':'menu_collapse_title_item pt-1 pb-1'" :to="(item.params)?{name: item.url, params: JSON.parse(item.params)}:{name: item.url}">{{item.title}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <div  v-if="!item.menu">
                        <b-container fluid  v-for="(sub, j) in item.menus" :key="'item'+j" class="p-0">
                            <b-dropdown-item class="menu_collapse_subitem pl-4 pt-1 pb-1" :to="(sub.params)?{name: sub.url, params: JSON.parse(sub.params)}:{name: sub.url}">{{sub.title}}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                        </b-container>
                    </div>
                </b-container>
            </b-dropdown>

        </div>
    </b-container>
</template>

<script>
    import { Icon } from '@iconify/vue2';
    import eyeIcon from '@iconify/icons-fa/eye';
    import menuFill from '@iconify/icons-eva/menu-fill';

    export default {
        name: "Menu",
        components: {
            'Icon': Icon,
        },
        data() {
            return {
                icons: {
                    eyeIcon,
                    menuFill
                },
                path: window.location.origin,
                userTheme: "light-theme",
                themeCheck: true
            };
        },
        computed: {
            menu() {
                return this.$store.getters.allMenu
            }
        },
        mounted() {
            this.$store.dispatch('getMenu')
        },
        methods: {
            filterEnable(list) {
                var result = [];
                Object.keys(list).forEach(function (item){
                    if (list[item].enabled == 1)
                        result.push(list[item])
                })               
                return result
            }
        },
    }
</script>

<style scoped>

    @media all and (min-width: 920px) {
        .navbar .nav-item .dropdown-menu{ display: none; }        
        .navbar .nav-item:hover .dropdown-menu{ display: block; }
        .navbar .nav-item .dropdown-menu{ margin-top:0; }
        .menu {margin-left: 0px}
    }

    .menu {
        margin-left: -42px;
        background-color: var(--background-color-secondary);
        z-index:99;
    }

    .menu-collapse {
        position: absolute;
        right: 2px;
        top: 20px;
        z-index: 99;
        cursor: pointer;
        background-color: var(--background-color-primary);
    }

    .menu_collapse_item, .menu_collapse_title_item {
        width: 420px;
        font-family: SF BOLD, sans-serif ;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-primary-color);
    }

    .menu_collapse_title_item {
        background-color: var(--background-color-primary);
    }

    ::v-deep .menu_collapse_title_item .dropdown-item{
        color: #FF7A16 !important;
    }

    .dropdown-divider {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .menu_collapse_subitem {
        font-family: SF, sans-serif ;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        color: var(--text-primary-color);
    }

    .menu_item{
        font-family: SF BOLD, sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--text-primary-color) !important;
        padding-left: 8px;
        padding-right: 8px;
    }

    .toggleTheme {
        cursor: pointer;
    }

    @media all and (max-width: 1200px) {
        .menu_item {
            padding-left: 2px;
            padding-right: 2px;
        }
    }

    ::v-deep .nav-link{
        color: var(--text-primary-color) !important;
    }

    ::v-deep .nav-link:hover{
        color: var(--accent-color) !important;
    }


    .menu_subitem {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        margin-top: 12px;
        line-height: 19px;
        color: var(--text-primary-color);
        width: 320px;
    }
    .sub_checked {
        font-weight: bold;
        color: #FF7A16;
    }

    ::v-deep .dropdown-item {
        white-space: normal !important;
        color: var(--text-secondary-color);
    }

    ::v-deep .dropdown-menu {
        padding-top: 2px;
        margin-top: -44px;
        margin-left: -2px;
        max-width: 400px;
        overflow: hidden;
        z-index:999;
        padding-bottom: 16px;
        border-radius: 12px;
        background-color: var(--background-color-primary);
    }

    .eye_button {
        position: absolute;
        margin-left: 12px;
        margin-top: -4px;
        line-height: 6px;
        border: 2px solid #f1a84e;
        padding: 4px 2px;
    }

    .eye_button_collapse {
        position: absolute;
        margin-top: -20px;
        right: 12px;
        line-height: 6px;
        border: 2px solid #f1a84e;
        padding: 4px 2px;
    }

</style>