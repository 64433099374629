<template>
    <b-container fluid="lg" class="pl-3 pl-sm-5 pl-md-5 pl-lg-2">
        <b-row class="ml-0 pl-0 mt-4 mb-3">
            <b-col cols="12" lg="6" class="ml-0 pl-0 mt-3 mb-3 mt-lg-1 mb-lg-1 text-left">
                <router-link :to="{name: 'home'}"><img :src="(getTheme=='light-theme')?info.logo.path:info.logo_footer.path" class="logo" v-if="info.logo" alt="ссылка на главную страницу"></router-link>
            </b-col>
            <b-col cols="12" lg="6">
                <b-row>

                    <b-col cols=7 sm="7" md="6" class="d-block d-lg-none pl-0 mt-0">
                        <div class="slogan float-left mr-3 mr-sm-1 mr-md-0 mt-0 mt-md-1">
                            {{info.slogan}}
                        </div>
                        <div class="icons pt-3 pt-sm-1  pl-0 pl-sm-2 float-left ml-0 ml-md-2">
                            <a :href="info.vk" target="_blank">
                                <Icon :icon="icons.vkWithCircle" color="#C5C6C6" height="36" />
                            </a>
                        </div>
                        <div class="icons" v-if="info.instagram">
                            <a :href="info.instagram" target="_blank">
                                <Icon icon="uil:instagram-alt" color="#777" height="38" :rotate="2" :horizontalFlip="true" :verticalFlip="true"  class="social_icon" title="вКонтакте"/>
                            </a>
                        </div>
                    </b-col>
                    <b-col cols=5 sm="5" md="6" lg="12" class="mt-1 mb-3 mb-md-3 mb-lg-0 pr-4 pr-lg-3">
                        <a target="_blank" href="https://kassa.dvpion.ru">
                            <b-button class="button ml-sm-5 ml-md-0" variant="primary">
                                Оплата
                            </b-button>
                        </a>
                        <router-link :to="{name: 'shedule'}">
                            <b-button class="button mr-0 mr-md-2 mt-2 mt-md-0" variant="secondary">
                                Расписание
                            </b-button>
                        </router-link>
                    </b-col>
                </b-row>
                <b-row class="d-none d-lg-block mt-4">
                    <b-col>
                        
                        <div class="icons mt-1">
                            <b-form-checkbox v-model="themeCheck" size="sm" switch style="margin-top: -2px; margin-right: 2px; float: left"><span class="toggleTheme" v-show="themeCheck">светлый режим</span><span class="toggleTheme" v-show="!themeCheck">темный режим</span></b-form-checkbox>
                            <div class="float-right ml-2"  style="font-size: 28px; margin-top: -12px"><b-icon id="specialButton" icon="eye-fill" class="toggleTheme" v-b-tooltip.hover title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" /></div>
                            <b-tooltip target="tooltip-target-1" triggers="hover">
                                ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ
                            </b-tooltip>
                        </div>
                        <div class="icons p-1">
                            <a :href="info.vk" target="_blank">
                                <Icon :icon="icons.vkWithCircle" color="#C5C6C6" height="24" />
                            </a>
                        </div>
                        <div class="icons" v-if="info.instagram">
                            <a :href="info.instagram" target="_blank">
                                <Icon icon="uil:instagram-alt" color="#777" height="38" :rotate="2" :horizontalFlip="true" :verticalFlip="true"  class="social_icon" />
                            </a>
                        </div>
                        <div class="slogan">
                            {{info.slogan}}
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import {Icon} from '@iconify/vue2';
    import vkWithCircle from '@iconify/icons-entypo-social/vk-with-circle';

    export default {
        name: "Header",
        components: {
            Icon
        },
        data() {
            return {
                icons: {
                    vkWithCircle,
                },
                userTheme: "light-theme",
                themeCheck: true
            };
        },
        mounted () {
            let fScript = document.createElement('script')
            fScript.setAttribute('src', 'https://lidrekon.ru/slep/js/jquery.js')
            let sScript = document.createElement('script')
            sScript.setAttribute('src', 'https://lidrekon.ru/slep/js/uhpv-hover-full.min.js')
            document.head.appendChild(fScript)
            document.head.appendChild(sScript)

            const initUserTheme = this.getTheme || this.getMediaPreference();
            this.setTheme(initUserTheme);
        },
        computed: {
            info() {
                return this.$store.getters.allInfo
            },
            getTheme() {
                return localStorage.getItem("user-theme");
            }
        },
        watch: {
            themeCheck(newValue) {
                const activeTheme = localStorage.getItem("user-theme");
                if (activeTheme === "light-theme") {
                    this.setTheme("dark-theme");
                } else {
                    this.setTheme("light-theme");
                }
                this.themeCheck=newValue;
            }
        },
        methods: {
            setTheme(theme) {
                this.userTheme = theme;
                this.$store.dispatch('setUserTheme', theme);
                document.documentElement.className = theme;
            },
            getMediaPreference() {
                const hasDarkPreference = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                ).matches;
                if (hasDarkPreference) {
                    return "dark-theme";
                } else {
                    return "light-theme";
                }
            }
        },
    }
</script>

<style scoped>
    .logo {
        width: 320px;
    }

    /deep/ .button {
        background-color: #FF6E00 !important;
        font-family: SF, sans-serif;
        font-style: normal;
        font-size: 13px;
        line-height: 19px;
        color: #FFF;
        border: 0px;
        border-radius: 6px;
        float: right;
        padding: 4px 32px 4px 32px;
    }
    /deep/ .button:hover {
        background-color: #af483a !important;
    }


    .slogan {
        font-family: SF, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: var(--text-primary-color);
        margin-top: 0px;
        float: right;
    }

    .icons {
        margin-left: 12px;
        margin-top: -6px;
        float: right;
    }

    /deep/ .iconify:hover {
        color: #2462c4 !important;
    }

    .toggleTheme {
        cursor: pointer;
        color: var(--text-primary-color) !important;
    }

    @media all and (max-width: 920px) {
        .button {
            width: 125px;
            padding: 4px 0px 4px 0px;
        }

        .logo {
            width: 260px;
        }
    }

    @media all and (max-width: 400px) {
        .slogan {
            font-size: 12px;
            line-height: 16px;
        }

        .logo {
            width: 240px;
        }

    }

</style>